<template>
    <div style="width:100%; display:flex;">
        <div style="width:70%;">
            <v-toolbar style="background:#cad9e5;">
                <v-row>
                    <v-btn
                        tile
                        color=""
                        style="margin-right:1%;"
                        @click="getDatas"
                        v-bind:disabled="this.cancelDisabled==false"
                    >
                        <v-icon left>
                            mdi-magnify
                        </v-icon>
                        검색
                    </v-btn>
                    <v-btn
                        tile
                        color="primary"
                        style="margin-right:1%;"
                        @click="stockMgmtExcelDownload"
                        v-bind:disabled="this.cancelDisabled==false"
                    >
                        <v-icon left>
                            mdi-download
                        </v-icon>
                        현황내역
                    </v-btn>
                </v-row>
            </v-toolbar>
            <v-toolbar style="background:#cad9e5;">
                <v-toolbar-items
                    style="margin-right:1%; margin-top:3%;"
                >
                    <v-text-field
                        id="wearing-item_code"
                        label="품번"
                        clearable
                        @change="value => onTextChangne('item_code', value)"
                        @input="onTextChangne('item_code', $event)"
                        @keyup="getDatas"
                        @keydown="disabledSelectAll"
                    ></v-text-field>
                </v-toolbar-items>
                <v-toolbar-items
                    style="margin-right:1%; margin-top:3%;"
                >
                    <v-text-field
                        id="wearing-item_name"
                        label="품명"
                        clearable
                        @change="value => onTextChangne('item_name', value)"
                        @input="onTextChangne('item_name', $event)"
                        @keyup="getDatas"
                        @keydown="disabledSelectAll"
                    ></v-text-field>
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table
                v-model="selected"
                ref="dataTable"
                :headers="headers"
                :items="datas"
                class="elevation-1"
                show-select
                item-key="id"
                @click:row="onClickRow"
                height="100%"
                fixed-header
                dense
                single-select

                :item-class="rowClass"
                :items-per-page="perPage"
                :footer-props="footerProps"
                :page.sync="page"
                :server-items-length="dataCounts"
                :options.sync="options"
            >
            </v-data-table>
        </div>

        <div style="width:30%;">
            <v-toolbar style="background:#cad9e5;">
                <v-row>
                </v-row>
            </v-toolbar>
            <v-toolbar style="background:#cad9e5;">
                <v-toolbar-items
                    style="margin-right:1%; margin-top:3%;"
                >
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table
                v-model="subSelected"
                :headers="subHeaders"
                :items="subDatas"
                class="elevation-1"
                item-key="id"
                height="100%"
                fixed-header
                dense
            >
            </v-data-table>
        </div>

        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="snack"
            :timeout="3000"
            :color="snackColor"
            top
        >
            {{ snackText }}
        </v-snackbar>

        <v-dialog
            v-model="dialogDelete"
            width="300px"
            max-width="300px"
        >
            <v-card style="height: 180px;" hidden>
                <v-card-title>삭제</v-card-title>
                <v-card-text>선택하신 항목을 삭제하시겠습니까?</v-card-text>
                <v-card-actions style="justify-content: end;" hidden>
                    <v-btn
                        color="orange"
                        text
                        @click="deleteItem"
                    >확인</v-btn>
                    <v-btn
                        color="orange"
                        text
                        @click="dialogDelete = false"
                    >취소</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "Release",
  components: {
    
  },
  data() {
    return {
        headers: [
            { value:'item_code', text:'품번', width:'12%', align:'center', sortable: true, },
            { value:'item_name', text:'품명', width:'13%', align:'center', sortable: true, },
            { value:'specification', text:'규격', width:'15%', align:'center', sortable: true, },
            { value:'detail_info', text:'상세사양', width:'15%', align:'center', sortable: true, },
            { value:'unit_code', text:'단위', width:'10%', align:'center', sortable: true, },
            { value:'sum_quan', text:'수량', width:'10%', align:'center', sortable: true},
            { value:'safe_quan', text:'안전재고', width:'10%', align:'center', sortable: true},
            { value:'last_in_date2', text:'입고일', width:'15%', align:'center', sortable: true},
        ],
        selected:[],
        datas:[],
        dataCounts:0,
        perPage:100,
        page:1,
        footerProps: { 'items-per-page-options': [100, -1] },
        options:{},
        params:{},

        orderBy:null,
        order_desc:null,

        subHeaders: [
            { value:'create_date', text:'입고일', width:'35%', align:'start', sortable: true, class: 'cell-header',},
            { value:'lotno', text:'LOT번호', width:'35%', align:'start', sortable: true, class: 'cell-header',},
            { value:'bin_code', text:'위치 정보', width:'35%', align:'start', sortable: true, class: 'cell-header',},
            { value:'quan', text:'수량', width:'30%', align:'center', sortable: true, class: 'cell-header',},
            { value:'blotid', text:'Batch LOTID', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'slotno', text:'공급사 LOT번호', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'validity_date', text:'유효기간', width:'10%', align:'start', sortable: true, class: 'cell-header', },
            { value:'scomp_code', text:'공급사CODE', width:'5%', align:'start', sortable: true, class: 'cell-header', },
            { value:'scomp_name', text:'공급사 업체명', width:'10%', align:'start', sortable: true, class: 'cell-header', },
        ],
        subSelected:[],
        subDatas:[],
        subParams:{},

        overlay: false,
        dialog_adjust:false,
        dialog_move:false,
        dialogCheck:false,
        textCheck:'',

        snack:false,
        snackColor:'',
        snackText:'',

        moveRules: {
            check: (quan, quan_edit) => {
                if(quan!=null && quan!=undefined && 
                    quan_edit!=null && quan_edit!=undefined) {
                    if(quan < quan_edit) {
                        return true || '현재수량 적은 값만 가능합니다.';
                    }
                }

                return true;
            }
        },

        deleteDisabled:true,
        dialogDelete:false,
        cancelDisabled:true
    };
  },
  mounted() {
    //this.init();    
  },
  watch: {
    selected() {
        if(this.selected.length>0) {
            this.deleteDisabled = false
            this.cancelDisabled = false
        } else {
            this.deleteDisabled = true
            this.cancelDisabled = true
        }
    },
    options(val) {
        var curPage = val.page;
        var itemsPerPage = val.itemsPerPage;
        if(itemsPerPage!=null && itemsPerPage==-1) {
            itemsPerPage = 100000000;
        }
        this.page = curPage;
        this.perPage = itemsPerPage;

        if(val.sortBy[0]){
            this.orderBy = "&orderBy=" + val.sortBy[0];
            this.order_Desc = "&isAsc=" +  val.sortDesc[0];
        }
        else{
            this.orderBy=null;
            this.order_Desc=null;
        }

        this.getDatas();
    },
  },
  methods: {
    init() {
        this.getDatas()
    },


    getDatas() {
        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var start = (this.page-1) * this.perPage;
        var limit = this.perPage;
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          start:start,
          limit:limit
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k];
                //console.log(params)
            }
        }
        url += params;

        if(this.orderBy != null && this.orderBy != 'undifined'){
            url += this.orderBy;
            url += this.order_Desc;
        }
        else{
            url += this.orderBy="&orderBy=unique_id";
            url += this.order_Desc="&isAsc=false";            
        }
        //console.log(url)
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.result;
              const count = res.count;
              this.datas = datas;
              this.dataCounts = count;
              //console.log(datas)
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            
          })
    },

    disabledSelectAll(){
        const selectedItems = this.$refs.dataTable.selection;

        for(var key in selectedItems) {
            selectedItems[key] = false;
            key = null;
        }
        this.selected = [];
    },

    onClickRow(record, row) {
        const isSelected = row.isSelected;
        if(isSelected) {
            row.select(false);
        } else {
            row.select(true);
        }

        if(record!=null) {
            const uid_item = record.unique_id
            this.subParams['uid_item'] = uid_item

            this.getSubDatas();
        }
    },

    onTextChangne(key, val) {
        if(val==null || val.length<1) {
            this.params[key] = '';
            this.subParams[key] = '';
        } else {
            this.params[key] = val;
            this.subParams[key] = val;
        }
    },

    getSubDatas() {
        var url = this.$vBACKEND_URL + '/stock.do?method=readLocation';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.subParams!=null) {
            for(var k in this.subParams) {
                params += '&' + k + '=' + this.subParams[k]
            }
        }
        
        url += params;

        url += "&orderBy=IFNULL(validity_date,'9999-12-31') asc,create_date";
        url += "&isAsc=true";    
        //console.log(url);
        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const datas = res.result;
              this.subDatas = datas;
          })
          .catch(err => {console.log(err)})
          .finally(() => {
            
          })
    },

    rowClass(item) {
        let sum_quan = item.sum_quan
        let safe_quan = item.safe_quan
        if(safe_quan!=null && safe_quan>0) {
            if(safe_quan>sum_quan) {
                return 'orange-row'
            }
        }
    },

    deleteItem() {
        const selects = this.selected;
        if(selects==null || selects.length<1) {
            this.snack = true
            this.snackColor = 'red'
            this.snackText = '선택하신 값을 확인해주세요'
            return null;
        }

        const unique_id = selects[0].unique_id;

        var url = this.$vBACKEND_URL + '/item.do?method=deleteItem';
        var obj = {
            uid_company:this.$store.getters.getUidCompany,
            user_uid:this.$store.getters.getUserUid,
            user_id:this.$store.getters.getUserId,
            user_name:this.$store.getters.getUserName,
            unique_id:unique_id
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        url += params;

        fetch(url)
          .then(res => res.clone().json())
          .then(res => {
              const result = res.result;
              let color = 'green';
              if(result == 'SUCCESS') {
                  color = 'green'
              } else {
                  color = 'red'
              }
              this.snack = true
              this.snackColor = color
              this.snackText = result
              this.dialogDelete = false;
              this.getDatas()
          })
          .catch(err => {
              console.log(err)
              
              this.snack = true
              this.snackColor = 'red'
              this.snackText = '삭제 실패'
          })
    },

    stockMgmtExcelDownload() {
        this.overlay = true

        var url = this.$vBACKEND_URL + '/item.do?method=readItem';
        var obj = {
          uid_company:this.$store.getters.getUidCompany,
          command:'EXCEL'
        }

        var params = '';
        for(var key in obj) {
          params += '&' + key + '=' + obj[key]
        }
        if(this.params!=null) {
            for(var k in this.params) {
                params += '&' + k + '=' + this.params[k]
            }
        }
        url += params;

        fetch(url)
        .then(res => res.clone().json())
        .then(res => {
            const excelPath = res.result;
            if(excelPath!=null) {
                location.href = this.$vCONTENT_URL + "/excel/" + excelPath
            } 
            this.overlay = false
        })
        .catch(err => {
            console.log(err)
            this.snack = true;
            this.snackColor = 'red';
            this.snackText = '실패'
            this.overlay = false
        })
        .finally(() => this.overlay = false)
    }
  }
};
</script>

<style>
.v-data-table__wrapper {
    height:72vh !important;
}
.orange-row {
    color: rgb(0, 0, 0);
    background-color: rgb(245, 148, 58);
}
.cell-header, .lotno-header, .item_code-header, .citem_name-header {
    border-right: 1px solid rgb(238, 235, 235); 
    background: linear-gradient(to right, #ff6b6b, #556270);
}  
tr.v-data-table__selected {
    color: rgb(251, 251, 248);
    background: #7d92f5 !important;
}  

</style>